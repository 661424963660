<template>
  <div class="base-input flex flex-main-center flex-cross-center">
    <img v-if="trueValue-range[0]<=0 || disabled" src="@/assets/minus_off.svg" class="icon"/>
    <img v-else src="@/assets/minus.svg" class="icon" @click="add(-1)"/>
    <div class="input" :class="disabled ? 'disabled' : ''">
      <span>{{trueValue}}</span>
      <span v-if="!disabled">℃</span>
    </div>
    <img v-if="trueValue-range[1]>=0 || disabled" src="@/assets/plus_off.svg" class="icon" />
    <img v-else src="@/assets/plus.svg" class="icon" @click="add(1)"/>
  </div>
</template>

<script>
export default {
  name: 'base-input',
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: [Number, String],
    range: {
      type: Array,
      default: () => [16, 32]
    }
  },
  watch: {
    disabled: {
      handler (nv) {
        if (nv) {
          this.trueValue = ''
        } else {
          this.trueValue = this.format(this.value)
          this.$emit('change', this.trueValue)
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      trueValue: ''
    }
  },
  methods: {
    format (val) {
      if (val - this.range[0] < 0) {
        return this.range[0]
      } else if (val - this.range[1] > 0) {
        return this.range[1]
      } else {
        return val
      }
    },
    add (delta) {
      const newValue = this.format(this.value + delta)
      if (this.trueValue !== newValue) {
        this.trueValue = newValue
        this.$emit('change', newValue)
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.base-input
  margin 20rpx 0 40rpx
.icon
  box(72, 72);
  pointer-events auto
.input
  box(502, 72);
  background: rgba(31,31,52,0.05);
  border-radius: 20rpx
  text-align center
  margin: 0 20rpx
  font(28, 72, $text)
  &.disabled
    color: rgba(0,0,0,.25)
</style>

<template>
  <div class="base-switch" :class="{active:checked}" @click="$emit('change', disabled?false:+!checked)">
    <div class="handler"></div>
  </div>
</template>

<script>
  export default {
    name: 'base-switch',
    model: {
      event: 'change',
      prop: 'checked'
    },
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      checked: {
        type: [Boolean, Number]
      }
    }
  }
</script>

<style scoped lang="stylus">
.base-switch
  box(84, 44)
  font-size 0
  padding 4rpx
  background: rgba(31,31,52,0.2);
  border-radius 22rpx;
  &.active
    background #1979ff
    .handler
      transform translateX(44rpx)
  .handler
    border-radius 50%
    box(37, 37)
    background: #fff;
    box-shadow: 2rpx 2rpx 4rpx rgba(0,0,0,0.14);
    position relative
    transition translate .4s
</style>

<template>
<!-- <div>
  <template v-slot:header> -->
  <div class="control">
    <div class="header flex flex-center">设备批量控制</div>
    <div class="content">
      <div class="extra">
      <div>控制范围：【{{floor.name}}<span v-if="room.id">-{{room.name}}</span>】的全部设备</div>
      <div>设备状态：{{queryType}}</div>
      <div>设备总数：{{total}}台</div>
      </div>
      <div class="block">
        <div class="block-title">设备开关</div>
        <split-checkbox :options="Object.values(DeviceUtils.Command.SWITCH)" v-model="formData.SWITCH"></split-checkbox>
      </div>
      <div class="block">
        <div class="block-title">运行模式</div>
        <split-checkbox :options="Object.values(DeviceUtils.Command.MODE)" v-model="formData.MODE" valueName="name"></split-checkbox>
      </div>
      <div class="block">
        <div class="block-title">风速调节</div>
        <split-checkbox :options="Object.values(DeviceUtils.Command.SPEED)" v-model="formData.SPEED"></split-checkbox>
      </div>
      <div class="block">
        <div class="block-title flex flex-cross-center">
          <span>温度调节</span>
          <base-switch class="temp-enable" v-model="tempEnable" :disabled="tempDisabled"></base-switch>
          <span class="tip">温度调节指令使能：{{tempEnable?'开':'关'}}</span>
        </div>
        <base-input v-model="formData.TEMP" :disabled="!tempEnable"></base-input>
      </div>
      <div class="block flex-between-center">
        <div class="block-title">用户可控</div>
        <!-- <base-switch v-model="formData.USERCONTROL"></base-switch> -->
       <split-checkbox :options="Object.values(DeviceUtils.Command.USERCONTROL)" v-model="formData.USERCONTROL"></split-checkbox>
      </div>
    </div>
    <div class="footer flex flex-center">
      <div class="cancel btn flex flex-center" @click="onCancel">重置指令</div>
      <div class="confirm btn flex flex-center" @click="onConfirm">发送指令</div>
    </div>
    <!-- <base-confirm :show.sync="showConfirm" @confirm="confirm" title="操作提示">{{confirmMessage}}</base-confirm> -->
  </div>
</template>


<script>
  // import BaseConfirm from '../components/base-confirm'
  import SplitCheckbox from '@/components/split-checkbox'
  import GroupCheckbox from '@/components/group-checkbox'
  import DeviceUtils from '@/utils/deviceUtil'
  import Command from '@/utils/command'
  import BaseInput from '@/components/base-input'
  import BaseSwitch from '@/components/base-switch'
  import { mapGetters } from 'vuex'
  // import BaseSwitch from '../components/base-switch'
  const preventMove = e => {
    e.preventDefault()
    return false
  }
  export default {
    name: 'deviceBath',
    components: { BaseInput, SplitCheckbox, BaseSwitch },
    data () {
      return {
        // showConfirm: false,
        tempEnable: false,
        tempDisabled: false,
        confirmMessage: '',
        DeviceUtils,
        total: null,
        queryType: null,
        formData: {
          deviceId: null,
          SWITCH: null,
          TEMP: 25,
          MODE: null,
          SPEED: null,
          USERCONTROL: null
        }
      }
    },
    computed: {
      ...mapGetters(['floor', 'room'])
    },
    watch: {
      'formData.SWITCH': {
        handler (newVal, oldVal) {
          if (newVal === DeviceUtils.Command.SWITCH.ON.value) { // 开机
            DeviceUtils.Command.SWITCH.OFF.settable = true
            // 关机状态下除开机和用户可控外 其余都不能控制
            for (const mode of Object.values(DeviceUtils.Command.MODE)) {
              mode.settable = true
            }
            for (const speed of Object.values(DeviceUtils.Command.SPEED)) {
              speed.settable = true
            }
            this.tempDisabled = false
          } else if (newVal === DeviceUtils.Command.SWITCH.OFF.value) {
            DeviceUtils.Command.SWITCH.ON.settable = true
            // 关机状态下除开机和用户可控外 其余都不能控制
            for (const mode of Object.values(DeviceUtils.Command.MODE)) {
              mode.settable = false
              this.formData.MODE = null
            }
            for (const speed of Object.values(DeviceUtils.Command.SPEED)) {
              speed.settable = false
              this.formData.SPEED = null
            }
            this.tempEnable = false
            this.tempDisabled = true
          }
        }
      },
      'formData.MODE': {
        handler (newVal, oldVal) {
          // 送风模式
          if (newVal && (newVal === DeviceUtils.Command.MODE.BLOW.name)) { // 送风模式 风速为中风 禁用自动和强劲风
            this.formData.SPEED = 3
            for (const speed of Object.values(DeviceUtils.Command.SPEED)) {
              speed.settable = true
            }
            DeviceUtils.Command.SPEED.AUTO.settable = false
            DeviceUtils.Command.SPEED.POWER.settable = false
            this.tempEnable = false
            this.tempDisabled = true
          } else if (newVal && (newVal !== DeviceUtils.Command.MODE.BLOW.name)) { // 非送风模式
            for (const speed of Object.values(DeviceUtils.Command.SPEED)) {
              speed.settable = true
            }
            this.formData.SPEED = null
            this.tempDisabled = false
          }
        },
      }
    },
    mounted () {
      const queryType = this.$route.query.queryType
      this.queryType = {
        1: '全部',
        2: '开机',
        3: '关机',
        4: '在线',
        5: '异常',
      }[queryType]
      this.total = this.$route.query.total
      // document.addEventListener('touchmove', preventMove, { passive: false })
    },
    destroyed () {
      this.onCancel()
    },
    methods: {
      confirm () {
        this.$router.back()
      },
      onCancel () {
        this.formData = {
          deviceId: null,
          SWITCH: null,
          TEMP: 25,
          MODE: null,
          SPEED: null,
          USERCONTROL: null
        }
        this.tempEnable = false
        this.tempDisabled = false
        DeviceUtils.Command.SWITCH.OFF.settable = true
        DeviceUtils.Command.SWITCH.ON.settable = true
        for (const mode of Object.values(DeviceUtils.Command.MODE)) {
          mode.settable = true
        }
        for (const speed of Object.values(DeviceUtils.Command.SPEED)) {
          speed.settable = true
        }
      },
      onConfirm () {
        const commands = [
          Object.values(DeviceUtils.Command.SWITCH).find(s => s.value === this.formData.SWITCH),
          Object.values(DeviceUtils.Command.MODE).find(s => s.name === this.formData.MODE),
          Object.values(DeviceUtils.Command.SPEED).find(s => s.value === this.formData.SPEED)
          // Object.values(DeviceUtils.Command.USERCONTROL).find(s => s.value === this.formData.USERCONTROL)
        ]
        if (this.tempEnable) {
          commands.push(Command.builder().setValue(this.formData.TEMP).setName('温度').setCode(DeviceUtils.CommandCode.TEMP))
        }
        if (this.formData.MODE === DeviceUtils.Command.MODE.WARM.name) { // 制热模式  带上电加热
          commands.push(DeviceUtils.Command.HEAT.ON)
        } else if (this.formData.MODE && (this.formData.MODE !== DeviceUtils.Command.MODE.WARM.name) && (this.formData.MODE !== DeviceUtils.Command.MODE.AUTO.name)) {
          commands.push(DeviceUtils.Command.HEAT.OFF)
        }
        if (this.formData.MODE && (this.formData.MODE !== DeviceUtils.Command.MODE.AUTO.name)) { // 其他模式  关闭全自动
          commands.push(Command.builder().setValue(0).setName(DeviceUtils.Command.MODE.AUTO.name).setCode(DeviceUtils.CommandCode.AUTO))
        }
        if (this.formData.USERCONTROL === DeviceUtils.Command.USERCONTROL.ON.value) {
           commands.push(Command.builder().setValue('1').setName('已锁定').setCode(DeviceUtils.CommandCode.LOCK))
        }
        if (this.formData.USERCONTROL === DeviceUtils.Command.USERCONTROL.OFF.value) {
           commands.push(Command.builder().setValue('0').setName('未锁定').setCode(DeviceUtils.CommandCode.LOCK))
        }

        this.batchCommands = commands.filter(command => command !== undefined)
        if (!this.batchCommands || this.batchCommands.length < 1) {
          this.$toast.show('请选择要执行的任务')
          return
        }
        this.$axios.post('/h5/device/h5BatchExec', {
          commands: this.batchCommands,
          addressId: this.room.id ? this.room.path + '-' + this.room.id : this.floor.path + '-' + this.floor.id,
          deviceStatus: Number.parseInt(this.$route.query.queryType)
        }).then(res => {
          if (res) {
            this.confirmMessage = '指令发送成功，请稍后刷新列表状态'
            this.onCancel()
            this.$router.back()
          } else {
            this.confirmMessage = '指令下发失败'
          }
          this.$toast.show(this.confirmMessage)
        })
      }
    }
  }
</script>

<style scoped lang="stylus">
  .control
    background #fff
    .header
      height 80rpx
      background rgb(45,41,115)
      font(32,48,#fff)
    .extra
      background #fff9ed
      padding 24rpx 32rpx
      margin -28rpx -32rpx 40rpx
      font(24,40,#f8a809)
    .content
      max-height calc(100% - 210rpx)
      overflow-y auto
      padding 28rpx 32rpx 112rpx
      box-sizing border-box
      .temp-enable
        margin: 0 22rpx
      .tip
        font-size 28rpx
        color rgba(0,0,0,.45)
      .row
        margin 40rpx 0
        .status
          font(28,44,$subtext)
          margin-right 20rpx
    .footer
      position fixed
      background: #fff
      bottom 0
      left 0
      width 100%
      height 112rpx
      box-shadow: 0 -4rpx 8rpx rgba(0,0,0,0.05);
      .btn
        width 280rpx
        height 80rpx
        font-size 32rpx
        border-radius 20rpx
        &.cancel
          background #edeff2
          color $menu
          margin-right 40rpx
        &.confirm
          background #1979FF
          color #fff
</style>

export default class Command {
    code
    name
    value

    static builder () {
      return new Command()
    }

    setCode (code) {
      this.code = code
      return this
    }

    setName (name) {
      this.name = name
      return this
    }

    setValue (value) {
      this.value = value
      return this
    }
}

<template>
  <div class="split-checkbox" @click="onclick">
    <div v-for="(option,index) in options" :key="index" :data-index="index" :class="['option',{active:value==option[valueName]}]" :style="option.settable !== undefined && !option.settable?'text-decoration:line-through':''" >
      {{option.name}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'split-checkbox',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: [String, Number],
    options: Array,
    valueName: {
      default: 'value'
    }
  },
  methods: {
    onclick (e) {
      // console.log('e', e.target)
      const index = e.target.getAttribute('data-index')
      if (index !== null) {
        if (this.options[index].settable !== undefined && !this.options[index].settable) {
          return
        }
        this.$emit('change', this.options[index][this.valueName])
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.split-checkbox
  font-size 0
  margin 20rpx 0 40rpx
.option
  display: inline-block
  width: 124rpx
  height: 72rpx
  box-sizing: border-box
  background: rgba(31,31,52,0.05);
  border-radius:20rpx;
  font(28,72,$subtext)
  text-align: center
  margin-right 16rpx
  &:last-child
    margin-right 0
  &.active
    background-color: rgba(25,121,255,0.12);
    color: #1979FF
</style>

<template>
  <div class="group-checkbox flex" @click="onclick">
    <div v-for="(option,index) in options" :key="index" :data-index="index" :class="['option', 'flex-auto', {active: value==option.value}]">
      {{option.name}}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'group-checkbox',
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      value: [String, Number],
      options: Array
    },
    methods: {
      onclick (e) {
        const index = e.target.getAttribute('data-index')
        this.$emit('change', this.options[index].value)
      }
    }
  }
</script>

<style scoped lang="stylus">
  .group-checkbox
    background rgba(31,31,52,0.05);
    border-radius 20rpx
    height: 72rpx
    font(28,72,$subtext)
    margin 20rpx 0 40rpx
  .option
    width: 100%
    text-align: center
    &.active
      background-color #fff
      box-shadow: 0 2rpx 8rpx rgba(0,0,0,0.1);
      border-radius: 20rpx;
      border: 1rpx solid rgba(0,0,0,0.04);
      color: #1979FF
</style>

/**
 * 设备工具类
 */
class DeviceUtil {
  static CommandCode = {
    SWITCH: 'SWITCH',
    TEMP: 'TEMP',
    MODE: 'MODE',
    SPEED: 'SPEED',
    LOCK: 'LOCK',
    AUTO: 'AUTO'
  }

  static Command = {
    SWITCH: {
      ON: {
        value: 1,
        name: '开机',
        code: 'SWITCH'
      },
      OFF: {
        value: 0,
        name: '关机',
        code: 'SWITCH'
      }
    },
    MODE: {
      WARM: {
        value: 1,
        name: '制热',
        code: 'MODE'
      },
      COLD: {
        value: 2,
        name: '制冷',
        code: 'MODE'
      },
      DEHUMIDIFICATION: {
        value: 3,
        name: '除湿',
        code: 'MODE'
      },
      BLOW: {
        value: 5,
        name: '送风',
        code: 'MODE'
      },
      AUTO: {
        value: 1,
        name: '全自动',
        code: 'AUTO'
      }
    },
    SLEEP: {
      ON: {
        value: 1,
        name: '开启',
        code: 'SLEEP'
      },
      OFF: {
        value: 0,
        name: '关闭',
        code: 'SLEEP'
      }
    },
    HEAT: {
      ON: {
        value: 1,
        name: '运行',
        code: 'HEATING'
      },
      OFF: {
        value: 0,
        name: '未运行',
        code: 'HEATING'
      }
    },
    USERCONTROL: {
      ON: {
        value: 1,
        name: '开启',
        code: 'USERCONTROL'
      },
      OFF: {
        value: 0,
        name: '关闭',
        code: 'USERCONTROL'
      }
    },
    SPEED: {
      AUTO: {
        value: 0,
        name: '自动风',
        code: 'SPEED'
      },
      // BREEZE: {
      //   value: 1,
      //   name: '微风',
      // code: 'SPEED'
      // },
      LOW: {
        value: 2,
        name: '低风',
        code: 'SPEED'
      },
      MEDIUM: {
        value: 3,
        name: '中风',
        code: 'SPEED'
      },
      HIGH: {
        value: 4,
        name: '高风',
        code: 'SPEED'
      },
      POWER: {
        value: 5,
        name: '强劲风',
        code: 'SPEED'
      }
      // ,
      // NATURE: {
      //   value: 6,
      //   name: '自然风',
      // code: 'SPEED'
      // }
    }
  }

  /**
   * 状态是否正确
   */
  static valid (status) {
    return status && Object.hasOwnProperty.call(status, 'isOnline')
  }

  /**
   * 判断设备是否在线
   * @param {设备状态} status
   */
  static isOnline (status) {
    if (status) {
      return status.isOnline === 1
    } else {
      return null
    }
  }

  /**
   * 判断设备是否开启
   * @param {设备状态} status
   */
  static isTurnOn (status) {
    if (status) {
      if (status.switchStateEnum) {
        return status.switchStateEnum.state === 1
      }
      return !(status.isShutdown === 1)
    } else {
      return null
    }
  }

  /**
   * 判断设备是否锁定
   * @param {设备状态} status
   */
  static isLock (status) {
    if (status) {
      return status.isLock === 1
    } else {
      return null
    }
  }

  /**
   * 组合命令
   * @param  {...any} commands 命令集合
   */
  static buildCommands (...commands) {
    return [...commands]
  }

  /**
   * 获取温度指令
   * @param {温度指令} temp
   */
  static buildTempCommand (temp) {
    return {
      code: DeviceUtil.CommandCode.TEMP,
      value: temp
    }
  }

  /**
   * 获取模式指令
   * @param {模式指令} command
   */
  static buildModeCommand (command) {
    return DeviceUtil.buildCommand(command, DeviceUtil.CommandCode.MODE)
  }

  /**
   * 获取模式指令
   * @param {模式指令} command
   */
  static buildSpeedCommand (command) {
    return DeviceUtil.buildCommand(command, DeviceUtil.CommandCode.SPEED)
  }

  /**
   * 获取开关机指令
   * @param {开关机指令} command
   */
  static buildSwitchCommand (command) {
    return DeviceUtil.buildCommand(command, DeviceUtil.CommandCode.SWITCH)
  }

  /**
   * 生成指令
   * @param {指令} command
   * @param {指令code} code
   */
  static buildCommand (command, code) {
    if (!DeviceUtil.validateCommand(command, code)) {
      return {}
    }
    return {
      code,
      value: command.value
    }
  }

  /**
   * 判断命令是否合法
   * @param {命令} command
   * @param {命令code} code
   */
  static validateCommand (command, code) {
    for (var k of Object.keys(DeviceUtil.Command[code])) {
      if (DeviceUtil.Command[code][k].value === command.value) {
        return true
      }
    }
    return false
  }
}

export default DeviceUtil
